// → global state for the whole website, ideally for handling cart or products order for quote

import React, { createContext, useState, useEffect } from "react";

// → states default value
const defaultValue = {
  orders: [],
  isProductSidebarOpen: false,
  urlSearch: "",
  selectedProduct: {},
  addUrlSearch: () => {},
  removeUrlSearch: () => {},
  addOrder: () => {},
  removeOrder: () => {},
  updateOrder: () => {},
};

// → init context
export const StoreContext = createContext(defaultValue);

// → states wrapper
export const StoreProvider = ({ children }) => {
  // → state for orders than can be used as a cart
  const [orders, setOrders] = useState(
    JSON.parse(localStorage.getItem("orders"))
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );

  // → state to display for opened product sidebar
  const [selectedProduct, setProduct] = useState({
    id: "",
    name: "",
    description: "",
    images: null,
    column1Heading: "",
    column2Heading: "",
    column3Heading: "",
    column4Heading: "",
    column5Heading: "",
    column6Heading: "",
    column1Subheading: "",
    column2Subheading: "",
    column3Subheading: "",
    column4Subheading: "",
    column5Subheading: "",
    column6Subheading: "",
    tableRows: [],
    relatedProduct: [],
  });

  // → state for open/close product sidebar
  const [isProductSidebarOpen, setProductSidebar] = useState(false);

  // → path/search term state
  const [urlSearch, setUrlSearch] = useState(
    typeof window !== `undefined` && window.location.search
      ? window.location.search.substring(1)
      : ""
  );

  // → users search input
  const [searchKeyword, setSearchKeyword] = useState("");

  // =============== OPENING ORDER BASED ON URL
  // watcher for url changes

  // → add url search to the url path
  const addUrlSearch = (path, val) => {
    // → window pushState for preventing the browser to reload
    window.history.pushState(null, null, `/${path}?${val}`);
    setUrlSearch(val);
  };

  // → search term resetter when user close the product sidebar
  const removeUrlSearch = path => {
    window.history.pushState(null, null, `/${path}`);
    setUrlSearch("");
    if (isProductSidebarOpen) {
      setProductSidebar(false);
      setProduct({
        id: "",
        name: "",
        description: "",
        images: null,
      });
    }
  };

  //  → orders watcher if users add/remove item
  useEffect(() => {
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  // → adding product
  const addOrder = (
    // → expected object for each item
    item = {
      id: "",
      name: "",
      quantity: 0,
      type: "",
      img: "",
      url: "",
      code: "",
    }
  ) => {
    // → check if item is already in the array
    const isOrderExist = orders.some(order => order.code === item.code);

    // → only add item if it doesn't exist yet
    if (!isOrderExist) {
      setOrders([...orders, item]);
    }
  };

  // → removing product
  const removeOrder = code => {
    // → remove product by its code value
    const updatedOrders = orders.filter(order => order.code !== code);
    setOrders(updatedOrders);
  };

  // → update selected product quantity
  const updateOrder = (code, qty) => {
    setOrders(
      // → return new products array with the update quantity
      orders.map(order => {
        if (code === order.code) {
          // → prevent number value to go below 0
          order.quantity = qty > 0 ? qty : "";
        }
        return order;
      })
    );
  };

  return (
    <StoreContext.Provider
      value={{
        urlSearch,
        setUrlSearch,
        addUrlSearch,
        removeUrlSearch,
        addOrder,
        isProductSidebarOpen,
        setProductSidebar,
        orders,
        removeOrder,
        updateOrder,
        selectedProduct,
        setProduct,
        setOrders,
        searchKeyword,
        setSearchKeyword,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
