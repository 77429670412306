exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contessi-products-js": () => import("./../../../src/pages/contessi-products.js" /* webpackChunkName: "component---src-pages-contessi-products-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-foundry-products-js": () => import("./../../../src/pages/foundry-products.js" /* webpackChunkName: "component---src-pages-foundry-products-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lance-products-js": () => import("./../../../src/pages/lance-products.js" /* webpackChunkName: "component---src-pages-lance-products-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-what-is-thermic-lance-js": () => import("./../../../src/pages/what-is-thermic-lance.js" /* webpackChunkName: "component---src-pages-what-is-thermic-lance-js" */),
  "component---src-pages-who-is-contessi-js": () => import("./../../../src/pages/who-is-contessi.js" /* webpackChunkName: "component---src-pages-who-is-contessi-js" */)
}

